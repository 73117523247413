@font-face {
  src: url(fonts/Andrew\ Footit\ -\ Hudson\ NY.otf);
  font-family: hudson;
}

@font-face {
  src: url(fonts/Akkur.otf);
  font-family: akurat;
}

@font-face {
  src: url(fonts/AkkurBol.otf);
  font-family: akurat;
  font-weight: bold;
}

@font-face {
  src: url(fonts/Aller_Bd.ttf);
  font-family: aller;
  font-weight: bold;
}

@font-face {
  src: url(fonts/Aller_Rg.ttf);
  font-family: aller; 
}

@font-face {
  src: url(fonts/RobotoSlab-Regular.ttf);
  font-family: robotoslab;
}

@font-face {
  src: url(fonts/RobotoSlab-Bold.ttf);
  font-family: robotoslab;
  font-weight: bold;
}

@font-face {
  src: url(fonts/Montserrat-VariableFont_wght.ttf);
  font-family: montserrat;
}

@font-face {
  src: url(fonts/NotoSansJP-VariableFont_wght.ttf);
  font-family: noto;
}

@font-face {
  src: url(fonts/MonumentExtended-Regular.otf);
  font-family: monumentextended;
}

@font-face {
  src: url(fonts/MonumentExtended-Ultrabold.otf);
  font-family: monumentextended;
  font-weight: bold;
}

@font-face {
  src: url(fonts/MeSansX-Regular.woff2);
  font-family: mesansx;  
}

@font-face {
  src: url(fonts/MeSansX-Bold.woff2);
  font-family: mesansx;
  font-weight: bold;
}

@font-face {
  src: url(fonts/Klavika-Bold.woff2);
  font-family: klavika;
  font-weight: bold;
}

@font-face {
  src: url(fonts/Klavika-Regular.woff2);
  font-family: klavika;  
}

@font-face {
  src: url(fonts/GT-America-Condensed-Bold.woff2);
  font-family: GT-America-Condensed;
  font-weight: bold;
}

@font-face {
  src: url(fonts/GT-America-Condensed-Medium.woff2);
  font-family: klavika;  
  font-weight: medium;
}

@font-face {
  src: url(fonts/GT-America-Standard-Bold.woff2);
  font-family: GT-America;
  font-weight: bold;
}

@font-face {
  src: url(fonts/GT-America-Standard-Regular.woff2);
  font-family: GT-America;    
}

body {
  margin: 0;
  font-family: var(--font-body), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-black);
  overflow-x: hidden;
  background-color: var(--background_1);
  /*min-height: 100vh;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wrapper {
  background: var(--background_1);
}

a {
  color: var(--text-black);
}

Link {
  color: var(--text-black);
}

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9k53qs');
  src:  url('fonts/icomoon.eot?9k53qs#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?9k53qs') format('truetype'),
  url('fonts/icomoon.woff?9k53qs') format('woff'),
  url('fonts/icomoon.svg?9k53qs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-card-1:before {
  content: "\e900";
}
.icon-arrows:before {
  content: "\e902";
}
.icon-football:before {
  content: "\e901";
}
